<template>
  <div class="page">
    <nav class="navbar navbar-light bg-light">
      <div class="container-fluid">
        <div class="d-flex">
          <button v-if="showBackButton()" class="btn btn-light bi bi-chevron-left btn-sm me-3" type="button"
            @click="$router.go(-1)"></button>
          <span class="navbar-brand mb-0 h1">{{ title ?? 'Export' }} </span>
        </div>
        <!-- {{ $route }} -->
        <form class="d-flex">
          <button type="button" class="btn btn-success bi bi-download me-2" @click="clickOk">
            Export
          </button>
          <button class="btn btn-danger bi bi-x-lg" type="button" @click="goBack()">
            Nazad
          </button>
        </form>
      </div>
    </nav>
    <div class="row" style="overflow: unset">
      <div class="col">
        {{ message }}
      </div>
    </div>

    <data-form2 :key="'head' + nextKey" :data="expData" :formFields="formFields" @changed="inputChanged"
      :readonly="true" minheight="1px" :nooverflow="true" />

    <div class="row" style="overflow: unset">
      <div class="col">
        <div class="actions">
          <vue-excel-xlsx ref="xlsxData" style="display: none" class="ms-5 btn btn-warning bi bi-download"
            :data="jsonData['data']" :columns="jsonData['columns']" file-name="Exported_data" :file-type="'xlsx'"
            :sheet-name="'Export'">
          </vue-excel-xlsx>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import dateUtils from "../../utils/date-common";
import invoiceDataService from "../../services/invoice.service";
import CountriesDataService from "../../services/countries.service";

export default {
  name: "export",
  components: {
    DataForm2,
  },
  props: {
    endpoint: {
      type: String,
      required: true,
      default: "",
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      nextKey: 0,
      jsonData: [],
      countriesList: [],
      coutriesMap : {},
    };
  },
  computed: {
    formFields() {
      return {
        rows: [
          [{ id: { type: "hidden" } }],
          [
            {
              from: { label: "Od datuma", type: "date" },
            },
            { to: { label: "Do datuma", type: "date" } },
            {},
            {},
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              type: {
                label: "Tip dokumenta",
                description:
                  "Izaberite tip dokumenta. Ako nije izabran tip, izveštaj će sadržati sve tipove dokumenta!",
                type: "dropdown",
                clearable: true,
                list: [
                  { id: "Normal", name: "Računi" },
                  { id: "Advance", name: "Avansni računi" },
                  { id: "Proform", name: "Predračuni" },
                ],
              },
            },
            { storno: { label: "Ukljuci stornirana dokumenta", type: "checkbox" } },
            {stornoOnly: { label: "Samo stornirana dokumenta", type: "checkbox" } },
            {},
          ],
          [
            {
              stations: {
                label: "Poslovnica",
                labelField: "code",
                type: "dropdown",
                url: "/stations?limit=100000&ascending=1",
                multiple: true,
                description:
                  "Izaberite jednu ili vise poslovnica. Ako nema izabranih poslovnica, izveštaj će sadržati dokumente svih poslovnica!",
              },
            },
            {
              customer: {
                label: "Kupac",
                description:
                  "Izaberite kupca. Ako nije izabran kupac, izveštaj će sadržati dokumenta svih kupaca!",
                type: "dropdown",
                url: "/customers?limit=100000&ascending=1",
                clearable: true,
              },
            }
          ],

        ],
      };
    },
    expData() {
      return {
        from: dateUtils.getFirstDayOfsMonth().toISOString().substring(0, 10),
        to: new Date().toISOString().substring(0, 10),
        type: "Normal",
        storno: true,
        stornoOnly: false,
        stations: null,
        customer: null,
      };
    },
  },
  methods: {
    ///check history count and go back or close window
    goBack() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        // @grant        window.close
        window.close();
      }
    },
    showBackButton() {
      return window.history.length > 1;
    },
    formatType(val) {
      switch (val) {
        case "Normal":
          return "Račun";
        case "Advance":
          return "Avansni račun";
        case "Proform":
          return "Predračun";
        default:
          return val;
      }
    },
    formatValues(val) {
      return Number(val);
    },
    inputChanged(e, name) {
      this.expData[name] = e;
    },
    closeModal() {
      this.$store.commit("app/hideModal");
    },
    async clickOk() {
      // console.log(this.expData);
      if (!this.expData.storno && this.expData.stornoOnly) {
        this.expData.storno = true;
      }
      // if (this.expData) return;
      invoiceDataService
        .exportInvoices(this.expData)
        .then((res) => {
          if (res.data.length === 0) {
            this.$store.commit("app/showAlert", {
            type: "warning",
            message: "Nema podataka za izabrane parametre!",
          });
            return;
          }
          this.jsonData["columns"] = [
            {
              label: "Id",
              field: "id",
            },
            {
              label: "Datum Fakture",
              field: "doc_date",
              dataFormat: dateUtils.formatDate,
            },
            {
              label: "Valuta",
              field: "exchange",
            },
            {
              label: "Datum Valute",
              field: "exchange_date",
              dataFormat: dateUtils.formatDate,
            },
            {
              label: "Kurs",
              field: "exchange_value",
              dataFormat: this.formatValues,
            },
            {
              label: "Broj Fakture",
              field: "doc_nr",
            },
            {
              label: "Tip dokumenta",
              field: "invoice_type",
              dataFormat: this.formatType,
            },
            {
              label: "Status",
              field: "invoice_status",
            },
            {
              label: "Predhodni dokument",
              field: "ref_doc_nr",
            },
            {
              label: "Outbound broj",
              field: "outbound_num",
            },
            {
              label: "Br.Rez / Ugovor",
              field: "reservation_num",
            },
            {
              label: "Država",
              field: "country",
            },
            {
              label: "SAOP Broj",
              field: "saop_num",},
            {
              label: "Poslovnica",
              field: "code",
            },
            {
              label: "Kupac",
              field: "customer_name",
            },
            {
              label: "Ukupan iznos EUR",
              field: "iznos_exc",
              dataFormat: this.formatValues,
            },
            {
              label: "Ukupan iznos RSD",
              field: "iznos",
              dataFormat: this.formatValues,
            },
            {
              label: "Osnovica PDV RSD",
              field: "osnovica",
              dataFormat: this.formatValues,
            },
            {
              label: "PDV RSD",
              field: "pdv",
              dataFormat: this.formatValues,
            },
            {
              label: "Osnovica PDV u valuti",
              field: "osnovica_e",
              dataFormat: this.formatValues,
            },
            {
              label: "PDV RSD u valuti",
              field: "tax_e",
              dataFormat: this.formatValues,
            },
            {
              label: "Uplaceno",
              field: "payed",
              dataFormat: this.formatValues,
            },
            {
              label: "Uplaceno u valuti",
              field: "payed_e",
              dataFormat: this.formatValues,
            },
            {
              label: "Preostalo za uplatu",
              field: "rest",
              dataFormat: this.formatValues,
            },
            {
              label: "Preostalo za uplatu u valuti",
              field: "rest_e",
              dataFormat: this.formatValues,
            },
            {
              label: "Komentar",
              field: "comment",
            },
            {
              label: "Komentar koji se ne stampa",
              field: "comment2",
            },
          ];
          console.log(`CountryList: ${this.countriesList}`);
          this.jsonData["data"] = res.data.map((item) => {
            return {
              ...item,
              'payed': item.invoice_type == 'Advance' ? item.payed : 0,
              'payed_e': item.invoice_type == 'Advance' ? item.payed_e : 0,
              "rest": item.invoice_type == 'Advance' ? Number(item.iznos) - Number(item.payed) : 0,
              "rest_e": item.invoice_type == 'Advance' ? Number(item.iznos_exc) - Number(item.payed_e): 0,
              "country": this.coutriesMap[item.countries_res] ?? "",
            };
          });

          setTimeout(() => {
            this.$refs["xlsxData"].exportExcel();
          }, 200);
        }).catch((e) => {
          console.log(e);
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: `Greška prilikom izvoza podataka!`,
          });
        })
    },
  },
  async mounted() {
    console.log("Export mounted");
    CountriesDataService.getFullList().then((res) => {
      this.countriesList = res.data.rows;
      this.countriesList.forEach((c) => {
        this.coutriesMap[c.id] = c.name;
      });
    });
  },
};
</script>

<style scoped>
.page {
  max-width: 1200px;
  margin-left: 30px;
  min-height: 800px;
}

.company {
  font-size: smaller;
}

input[type="number"] {
  text-align: right;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>